<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-5">
    <h4
      class="capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Payroll / WorknProsper Salary Advance {{ isFilter }}
    </h4>

    <div class="flex items-center gap-2">
      <h2 class="text-left font-bold text-xl lg:text-2xl">
        WorknProsper Salary Advance
      </h2>

      <div
        @click="sideBar = !sideBar"
        class="cursor-pointer h-10 w-10 rounded-xl bg-white flex items-center justify-center border border-outline"
      >
        <i class="pi pi-exclamation-circle text-dark-600" />
      </div>
    </div>

    <!-- ////// Empty State ////// -->
    <div
      v-if="!employee.length && !isFilter"
      class="flex flex-col gap-6 p-4 sm:p-5 lg:p-6 rounded-3xl bg-white border border-outlineGray"
    >
      <!-- /////// Info /////// -->
      <div
        v-if="showInfo"
        style="
          background: linear-gradient(
            170.25deg,
            #11453b 7.33%,
            #2aab92 109.55%
          );
        "
        class="text-white relative rounded-2xl overflow-hidden"
      >
        <img
          class="absolute -right-10 -top-28 pointer-events-none"
          src="@/assets/img/polygon4.svg"
          alt=""
        />
        <img
          width="250px"
          class="absolute right-10 top-4 pointer-events-none"
          src="@/assets/img/person-yellow.png"
          alt=""
        />

        <i
          @click="showInfo = false"
          class="pi pi-times text-sm text-white cursor-pointer absolute right-4 top-4"
        />

        <div class="flex flex-col gap-4 p-4 sm:p-5 md:p-6 w-full md:w-7/12">
          <h4 class="text-xl sm:text-2xl text-white font-bold">
            Introducing the Salary Advance feature!
            <br class="hidden sm:block" />
            Empower your workforce with financial freedom
          </h4>

          <p class="text-sm sm:text-base text-light">
            Now, with ease, you can offer advances to your employees
            <br class="hidden sm:block" />
            through the WorknProsper app.
          </p>

          <div>
            <button
              @click="howItWorksModal = true"
              style="background-color: #11453b"
              class="px-6 py-3 rounded-full text-sm w-fit focus:border-none focus:outline-none"
            >
              See how it works
            </button>
          </div>
        </div>
      </div>

      <div
        class="rounded-2xl bg-white border border-outlineGray p-4 flex items-center justify-center"
      >
        <div class="flex flex-col gap-4 items-center my-20">
          <div>
            <img src="@/assets/icons/wnpempty.svg" alt="" />
          </div>

          <span class="text-sm font-semibold text-center">
            Kindly add the employees you would love to
            <br class="hidden sm:block" />
            benefit from this
          </span>

          <easiButton @click="isOpen = true">Add Employees</easiButton>
        </div>
      </div>
    </div>

    <!-- ////// List Employee ////// -->
    <div v-else class="flex flex-col gap-5">
      <div class="flex flex-col md:flex-row items-center justify-between gap-4">
        <div class="flex-grow w-full">
          <easiSearch
            placeholder="Search employee "
            v-model="searchInput"
            @search="handleSearch"
            :suggestion="false"
          />
        </div>

        <div class="flex items-center gap-4">
          <SalaryAdanceFilter
            v-model:isFilter="isFilter"
            v-model:loading="loading"
          />

          <easiButton @click="isOpen = true" class="gap-2">
            <i class="pi pi-plus text-sm" /> Add New Employee</easiButton
          >
        </div>
      </div>

      <ListEmployees v-model:loading="loading" />
    </div>

    <InfoSidebar v-model:visible="sideBar" />
    <AddEditEmployee :isOpen="isOpen" @close="isOpen = false" />
    <easiRoundLoader v-if="loadingModal" />
    <HowItWorks :isOpen="howItWorksModal" @close="howItWorksModal = false" />
  </main>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { querySalaryAdvanceEmployee } from "@/services/salaryAdvance";

import HowItWorks from "@/components/Payroll/SalaryAdvance/HowItWorks.vue";
import AddEditEmployee from "@/components/Payroll/SalaryAdvance/AddEditEmployee.vue";
import InfoSidebar from "@/components/Payroll/SalaryAdvance/InfoSidebar.vue";
import ListEmployees from "@/components/Payroll/SalaryAdvance/ListEmployees.vue";
import SalaryAdanceFilter from "@/components/Payroll/SalaryAdvance/SalaryAdanceFilter.vue";

import { useDataStore } from "@/stores/data";
import { debouncedValue } from "@/composable/debouncedValue";

const store = useDataStore();

const isOpen = ref(false);
const loading = ref(false);
const loadingModal = ref(false);
const sideBar = ref(false);
const howItWorksModal = ref(false);

const isFilter = ref(false);
const searchInput = ref("");
const debounceSearch = debouncedValue(searchInput);
const showInfo = ref(true);

const employee = computed(() =>
  store.getSalaryAdvanceEmployee ? store.getSalaryAdvanceEmployee.docs : []
);

const handleSearch = async () => {
  try {
    isFilter.value = true;
    loading.value = true;
    await querySalaryAdvanceEmployee({ search: searchInput.value });
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

watch(debounceSearch, () => {
  handleSearch();
});

onMounted(async () => {
  try {
    loadingModal.value = !employee.value.length;
    await querySalaryAdvanceEmployee();
  } catch (error) {
    console.log(error);
  } finally {
    loadingModal.value = false;
  }
});
</script>

<style></style>
