<template>
  <div class="relative">
    <easiButton
      rounded="2xl"
      variant="outlined"
      color="gray"
      @click="isOpen = true"
      class="bg-white text-dark-600 gap-2"
    >
      <img class="" src="@/assets/icons/filter.svg" alt="" />
      Filter {{ activeFilter && "-" }}
      {{ activeFilter ? activeFilter.title : "" }}
    </easiButton>
  </div>

  <easiModal
    v-if="isOpen"
    @close="handleClose"
    :isHeader="false"
    :isBorder="false"
    lite
    center
    rounded="3xl"
  >
    <div
      class="relative flex flex-col gap-4 rounded-3xl bg-white py-6 p-4 md:px-6"
    >
      <h1 class="capitalize text-center text-dark-900 text-2xl">Filter</h1>

      <span @click="handleClose" class="cursor-pointer absolute right-4 top-4">
        <img src="@/assets/icons/close-icon-r.svg" alt="" />
      </span>

      <div
        :class="[loading ? 'pointer-events-none opacity-80' : '']"
        class="border-t border-b py-4 flex flex-col gap-4"
      >
        <div>
          <h5 class="mb-2">Sort by - Employee Name</h5>
          <easiTab
            classic
            class="max-w-2xl"
            :tabItems="sortByName"
            v-model:active="args.sort.name"
          />
        </div>
        <div>
          <h5 class="mb-2">Sort by - Net Salary</h5>
          <easiTab
            classic
            class="max-w-2xl"
            :tabItems="sortBySalary"
            v-model:active="args.sort.netSalary"
          />
        </div>
        <div>
          <h5 class="mb-2">Eligility</h5>
          <easiTab
            classic
            class="max-w-2xl"
            :tabItems="sortByEligibility"
            v-model:active="args.filter.eligibilityStatus"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 pt-2 pb-1">
        <easiButton
          :disabled="loading"
          :loading="resetLoading"
          @click="handleReset"
          size="medium"
          block
          variant="outlined"
          >Reset</easiButton
        >
        <easiButton :loading="loading" @click="handleFilter" size="medium" block
          >Filter</easiButton
        >
      </div>
    </div>
  </easiModal>
</template>

<script setup>
import { ref } from "vue";
import { querySalaryAdvanceEmployee } from "@/services/salaryAdvance";

const props = defineProps({
  loading: Boolean,
  isFilter: Boolean,
});
const emit = defineEmits(["update:loading", "update:isFilter"]);

const isOpen = ref(false);
const activeFilter = ref(null);

const sortByName = ref(["DEFAULT", "A - Z", "Z - A"]);
const sortBySalary = ref(["DEFAULT", "HIGH - LOW", "LOW - HIGH"]);
const sortByEligibility = ref(["DEFAULT", "ELIGIBLE", "INELIGIBLE"]);

const sortObject = {
  DEFAULT: "ASC",
  "A - Z": "DESC",
  "Z - A": "DESC",
};
const filterObject = {
  DEFAULT: "ELIGIBLE",
  ELIGIBLE: "ELIGIBLE",
  INELIGIBLE: "INELIGIBLE",
};

const resetLoading = ref(false);
const args = ref({
  sort: {
    netSalary: "DEFAULT",
    name: "DEFAULT",
  },
  filter: {
    eligibilityStatus: "DEFAULT",
  },
});

const handleReset = async () => {
  try {
    emit("update:loading", true);
    resetLoading.value = true;
    args.value.sort.name = "DEFAULT";
    args.value.filter.eligibilityStatus = "DEFAULT";
    await querySalaryAdvanceEmployee();
    isOpen.value = false;
  } catch (error) {
  } finally {
    emit("update:loading", false);
    resetLoading.value = false;
  }
};

const handleFilter = async () => {
  const payload = {
    sort: {
      name: sortObject[args.value.sort.name],
      netSalary: sortObject[args.value.sort.netSalary],
    },
    filter: {
      eligibilityStatus: filterObject[args.value.filter.eligibilityStatus],
    },
  };

  try {
    emit("update:loading", true);
    emit("update:isFilter", true);
    const res = await querySalaryAdvanceEmployee(payload);
    if (res.data.docs && res.data.docs.length) {
      emit("update:isFilter", false);
    }

    isOpen.value = false;
  } catch (error) {
    console.log(error);
  } finally {
    emit("update:loading", false);
  }
};

const handleClose = () => {
  isOpen.value = false;
};
</script>

<style></style>
