<template>
  <easiModal
    v-if="isOpen"
    @close="handleClose"
    :isHeader="false"
    :isBorder="false"
    lite
    center
    :persistence="false"
    rounded="3xl"
  >
    <div class="flex flex-col gap-4 rounded-3xl bg-white py-6 p-4 md:px-6">
      <div v-if="!showCalendar">
        <h1 class="capitalize text-center text-dark-900 text-2xl font-bold">
          Duration
        </h1>

        <div class="flex flex-col w-full px-4 pb-3 md:px-6">
          <easiTextInput
            label="From"
            :disabled="loading"
            placeholder="From"
            @focus="openCalendar('from')"
            v-model="args.from"
          >
            <template #prependIcon>
              <i class="pi pi-chevron-down text-sm" />
            </template>
          </easiTextInput>
          <easiTextInput
            label="To"
            :disabled="loading"
            @focus="openCalendar('to')"
            placeholder="To"
            v-model="args.to"
            ><template #prependIcon>
              <i class="pi pi-chevron-down text-sm" />
            </template>
          </easiTextInput>

          <easiButton
            class="mt-6"
            :disabled="!args.from || !args.to"
            :loading="loading"
            block
            @click="submit"
            >Save</easiButton
          >
        </div>
      </div>

      <div v-else>
        <easiCalendar :date="dateInput" @select="handleDateSelect" />
      </div>
    </div>
  </easiModal>

  <!-- //////  Alert Modal ////// -->

  <easiAlert
    v-if="isAlert"
    :title="`Add duration`"
    :caption="`Duration added successfully!`"
  >
    <div class="pb-4 px-6">
      <easiButton size="medium" @click="isAlert = false" block
        >Dismiss!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { addCategory, updateCategory } from "@/services/book-keeping/settings";
import { useToast } from "vue-toastification";
import { updateSalaryAdvanceEmployee } from "@/services/salaryAdvance";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatDate, monthDifferent } = helperFunctions;

const toast = useToast();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  data: [Object, null],
  editMode: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close"]);

const showCalendar = ref(false);
const isAlert = ref(false);
const loading = ref(false);

const dateInput = ref();
const currentField = ref("");
const args = ref({
  from: "",
  to: "",
});

const openCalendar = (arg) => {
  currentField.value = arg;
  dateInput.value = args.value[arg];
  showCalendar.value = true;
};

const handleDateSelect = (e) => {
  console.log(e);
  if (currentField.value === "to") {
    const difference = monthDifferent(args.value.from, e);
    if (difference < 1) {
      toast.error("To must not be less than from");
      return;
    }
  }

  args.value[currentField.value] = formatDate(e, "MMM, YYYY");
  showCalendar.value = false;
  dateInput.value = null;
};

const handleClose = () => {
  showCalendar.value = false;
  dateInput.value = null;
  args.value.from = "";
  args.value.to = "";
  emit("close");
};

const submit = async () => {
  const duration = monthDifferent(args.value.from, args.value.to);
  if (duration < 1) return;

  const payload = {
    duration,
    eligibilityStatus: props.data.eligibilityStatus,
    employeeId: props.data._id,
  };

  try {
    loading.value = true;
    const res = await updateSalaryAdvanceEmployee(payload);
    if (res.success) {
      handleClose();
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
