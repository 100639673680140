import { useDataStore } from "@/stores/data.js";
const store = useDataStore();
const { query, mutate } = store;
//// QUERIES /////

const service = "LOAN";

export const querySalaryAdvanceEmployee = async (pagination) => {
  const payload = {
    input: {
      filter: {},
      limit: 20,
      page: 1,
      search: null,
      sort: {},
      ...pagination,
    },
  };
  try {
    const res = await query({
      endpoint: "ListEmployeesSalaryAdvance",
      payload,
      service,
    });

    if (res.success) {
      store.$patch({
        salaryAdvanceEmployees: res.data,
      });
    }

    console.log(res);
    return res;
  } catch (error) {
    throw error;
  }
};

//// MUTATIONS /////

export const updateSalaryAdvanceEmployee = async (payload, bulk = false) => {
  const endpoint = bulk ? "UpdateSalaryAdvanceBulk" : "UpdateSalaryAdvance";

  try {
    const res = await mutate({
      endpoint,
      data: { input: payload },
      service,
    });

    if (res.success) {
      querySalaryAdvanceEmployee();
    }

    return res;
  } catch (error) {
    throw error;
  }
};
