<template>
  <div class="flex flex-col gap-2 sm:gap-4">
    <div class="flex items-center justify-between gap-4">
      <h3 class="font-semibold text-lg md:text-xl">Employee list</h3>
      <easiPaginAsync
        infoOnly
        leftAlign
        :totalRecords="totalRecords"
        v-model:rowsPerPage="paginationPayload.limit"
        v-model:page="paginationPayload.page"
      />
    </div>

    <easiPlainTable
      :data="visibleData"
      :headers="tableHeaders"
      :loading="loading"
      v-model:selected="selectedData"
    >
      <template #name="slotsProp">
        <div class="flex items-center gap-3 whitespace-nowrap">
          <div
            class="w-9 h-9 flex-shrink-0 overflow-hidden rounded-full bg-primary text-highlight flex items-center justify-center"
          >
            <img
              v-if="slotsProp.data.picture"
              :src="slotsProp.data.picture"
              class="h-full w-full object-cover"
              alt=""
            />
            <div v-else class="font-bold">
              {{ initials(null, slotsProp.data.name) }}
            </div>
          </div>
          <span>{{ slotsProp.data.name.split(" ")[0] || "N/A" }}</span>
        </div>
      </template>

      <template #status="slotsProp">
        <div class="flex items-center md:justify-center">
          <div
            :style="getStatusColor(slotsProp.data.eligibilityStatus)"
            class="px-4 py-1 border rounded-lg"
          >
            {{ slotsProp.data.eligibilityStatus }}
          </div>
        </div>
      </template>
      <template #action="slotsProp">
        <div class="md:text-center w-fit">
          <easiPopover
            :modify="{ left: 40 }"
            class="text-left"
            :data="slotsProp.data"
            :options="dropdownOptions(slotsProp.data)"
          >
            <i class="pi pi-ellipsis-v text-dark-600" />
          </easiPopover>
        </div>
      </template>
    </easiPlainTable>

    <easiPaginAsync
      :totalRecords="totalRecords"
      v-model:rowsPerPage="paginationPayload.limit"
      v-model:page="paginationPayload.page"
    />
  </div>

  <AddEditDuration
    :data="selectedData"
    :isOpen="durationModal"
    @close="durationModal = false"
  />
  <easiRoundLoader v-if="loadingModal" />
</template>

<script setup>
import AddEditDuration from "./AddEditDuration.vue";

import { computed, reactive, ref, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import {
  querySalaryAdvanceEmployee,
  updateSalaryAdvanceEmployee,
} from "@/services/salaryAdvance";
import { useToast } from "vue-toastification";

const { formatAmount, formatDate, initials } = helperFunctions;
const toast = useToast();
const store = useDataStore();

const props = defineProps({
  loading: Boolean,
});
const emit = defineEmits(["update:loading"]);

const loadingModal = ref(false);
const durationModal = ref(false);
const selectedData = ref(null);

const totalRecords = computed(() =>
  store.getSalaryAdvanceEmployee
    ? store.getSalaryAdvanceEmployee.paginationData.totalRecords
    : 0
);
const paginationPayload = reactive({
  limit: 20,
  page: 1,
});

watch(paginationPayload, async () => {
  try {
    emit("update:loading", true);
    await querySalaryAdvanceEmployee(paginationPayload);
  } catch (error) {
    console.log(error);
  } finally {
    emit("update:loading", false);
  }
});

const visibleData = computed(() =>
  store.getSalaryAdvanceEmployee ? store.getSalaryAdvanceEmployee.docs : []
);
console.log("Employees", visibleData.value);

const tableHeaders = [
  {
    style: ``,
    title: "First Name",
    field: "firstName",
    highlight: true,
    type: "name",
  },
  {
    style: ``,
    title: "Last Name",
    highlight: true,
    field: "name",
    format: (val) => {
      return val ? val.split(" ")[1] || "N/A" : "--";
    },
  },
  {
    style: ``,
    title: "Email Address",
    field: "email",
    format: (val) => {
      return val ? val : "--";
    },
  },
  {
    style: ``,
    title: "Net Pay",
    field: "netSalary",
    format: (val) => {
      return val ? formatAmount(val) : "--";
    },
  },
  {
    style: ``,
    title: "Status",
    field: "eligibilityStatus",
    type: "status",
  },
  {
    style: ``,
    title: "Duration",
    field: "duration",
    format: (val) => {
      return `${val} months` || "--";
    },
  },
  {
    style: ``,
    title: "Action",
    field: "action",
    type: "action",
  },
];

const getStatusColor = (status) => {
  if (!status) return "";
  return status === "ELIGIBLE"
    ? "color: #219653; background:#E5EEE9"
    : "color: #EF4444; background:#F5E7E7";
};

const dropdownOptions = (data) => {
  return [
    {
      title:
        data.eligibilityStatus === "ELIGIBLE"
          ? "Make ineligible"
          : "Make eligible",
      action: async (val) => {
        try {
          loadingModal.value = true;
          const payload = {
            duration: val.duration,
            eligibilityStatus:
              val.eligibilityStatus === "ELIGIBLE" ? "INELIGIBLE" : "ELIGIBLE",
            employeeId: val._id,
          };

          const res = await updateSalaryAdvanceEmployee(payload);
          if (res.success) {
            toast.success("Eligibility updated");
          } else {
            toast.error(res.message);
          }
        } catch (error) {
        } finally {
          loadingModal.value = false;
        }
      },
    },
    {
      title: "Edit duration",
      action: (val) => {
        selectedData.value = val;
        durationModal.value = true;
      },
    },
  ];
};
</script>

<style></style>
