<template>
  <div class="card flex justify-content-center">
    <Sidebar
      v-model:visible="toggle"
      header="WorknProsper Salary Advance"
      position="right"
      class="w-full max-w-md"
    >
      <p>
        Lorem ipsum dolor sit amet consectetur. Consectetur libero et volutpat
        mattis. Amet sed aliquet eu etiam turpis. Sagittis neque et pretium
        ultrices nibh sed suscipit. Nunc vitae et nam nec. Nunc ridiculus felis
        est placerat vel ornare augue. Ac dolor adipiscing eget etiam. In massa
        nisl molestie nunc lacus. Mi eu bibendum auctor malesuada aliquam
        ornare. Vitae quis duis nunc vestibulum elit sed. Proin amet etiam morbi
        scelerisque adipiscing. Sodales massa posuere sed lacinia. Eu at est
        bibendum fames eget nibh. Sed arcu nibh senectus massa ac sit non.
        Faucibus faucibus euismod aliquam faucibus.
      </p>
    </Sidebar>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:visible"]);

const toggle = computed({
  get() {
    return props.visible;
  },
  set(val) {
    emit("update:visible", false);
  },
});
</script>

<style></style>
