<template>
  <easiModal
    compliance
    v-if="isOpen"
    @close="handleClose"
    :isHeader="false"
    :isBorder="false"
    :persistence="false"
    center
    :showCloseButton="false"
    rounded="3xl"
  >
    <div class="flex flex-col gap-4 p-4 md:px-6">
      <h3
        class="capitalize text-center text-dark-900 text-2xl font-bold pt-6 pb-2"
      >
        Kindly select employees that will <br />
        benefit from Salary Advance
      </h3>

      <easiSearch
        :disabled="loading"
        placeholder="Search employee "
        v-model="searchInput"
        @search="handleSearch"
        :suggestion="false"
      />

      <div
        :class="[loading ? 'pointer-events-none opacity-80' : '']"
        class="flex items-center gap-4 justify-between text-sm mt-4 px-2"
      >
        <div class="flex items-center gap-6">
          <easiCheckBoxPlain :value="toggleAll" @change="handleToggleAll" />
          <span class="uppercase">Employee name</span>
        </div>
        <div class="uppercase">Net salary</div>
      </div>

      <!-- //////// List of Employeess ////// -->
      <div
        :class="[loading ? 'pointer-events-none opacity-80' : '']"
        style="max-height: 22rem"
        class="overflow-auto"
      >
        <div
          v-for="emp in visibleData"
          :key="emp"
          class="flex odd:bg-grayLight items-center gap-4 justify-between text-sm p-2"
        >
          <div class="flex items-center gap-6">
            <easiCheckBoxPlain
              :value="selectedEmployees.includes(emp._id)"
              @change="handleSelect(emp._id)"
            />

            <div class="flex items-center gap-3">
              <div
                class="w-8 h-8 flex-shrink-0 overflow-hidden rounded-full bg-primary text-highlight flex items-center justify-center"
              >
                <img
                  v-if="false"
                  :src="''"
                  class="h-full w-full object-cover"
                  alt=""
                />
                <div v-else class="font-bold">
                  {{ initials(emp) }}
                </div>
              </div>
              <span>{{ getFullName(emp) }}</span>
            </div>
          </div>

          <strong class="uppercase">{{ formatAmount(emp.netSalary) }}</strong>
        </div>
      </div>
    </div>

    <!-- ///// Footer ////// -->
    <div class="border-t border-outline py-3 px-4 md:px-6 flex flex-col gap-4">
      <div
        v-if="selectedEmployees.length"
        class="p-3 text-sm rounded-lg bg-grayLight border"
      >
        Total selected : <strong>{{ selectedEmployees.length }}</strong>
      </div>
      <easiButton
        :loading="loading"
        @click="submit"
        :disabled="!selectedEmployees.length"
        block
        >Add Employees</easiButton
      >
    </div>
  </easiModal>

  <easiAlert
    useGif
    v-if="isAlert"
    title="Employee added <br/> successfully!"
    caption=""
  >
    <div class="pb-4 px-6">
      <easiButton size="medium" @click="isAlert = false" block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { updateSalaryAdvanceEmployee } from "@/services/salaryAdvance";
const { formatAmount, initials, getFullName } = helperFunctions;
import { useDataStore } from "@/stores/data";
import { useToast } from "vue-toastification";

const toast = useToast();
const store = useDataStore();
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close"]);

const isAlert = ref(false);
const loading = ref(false);
const searchInput = ref("");
const toggleAll = computed(() => {
  return selectedEmployees.value.length === employees.value.length;
});

const selectedEmployees = ref([]);
const visibleData = ref([]);
const employees = computed(() => {
  const arr = store.getAllEmployees ? store.getAllEmployees.data : [];

  return arr.map((emp) => {
    const { pfp, firstName, lastName } = emp.profile;
    const { netAmount } = emp.staffLevel;

    return {
      pfp,
      firstName,
      lastName,
      netSalary: netAmount || 0,
      _id: emp._id,
    };
  });
});

console.log(employees.value);

const handleToggleAll = (e) => {
  if (!employees.value.length) return;
  if (e) {
    selectedEmployees.value = employees.value.map((el) => el._id);
  } else {
    selectedEmployees.value = [];
  }
};

const handleSelect = (empId) => {
  //check if employee is already selected
  const isSelected = selectedEmployees.value.find((el) => el === empId);
  if (isSelected) {
    selectedEmployees.value = selectedEmployees.value.filter(
      (el) => el !== empId
    );
    return;
  }

  selectedEmployees.value.push(empId);
};

const handleSearch = () => {
  if (loading.value) return;

  if (searchInput.value) {
    const lowerSearchTerm = searchInput.value.trim().toLowerCase();
    visibleData.value = employees.value.filter(
      (el) =>
        el.firstName.toLowerCase().includes(lowerSearchTerm) ||
        el.lastName.toLowerCase().includes(lowerSearchTerm) ||
        el.netSalary.toString().includes(lowerSearchTerm)
    );
  } else {
    visibleData.value = employees.value;
  }
};

const submit = async () => {
  const payload = {
    eligibilityStatus: "ELIGIBLE",
    employeeIds: selectedEmployees.value,
  };

  try {
    loading.value = true;

    const res = await updateSalaryAdvanceEmployee(payload, true);
    if (res.success) {
      handleClose();
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const handleClose = () => {
  emit("close");
};

watch(searchInput, handleSearch);

watch(employees, () => {
  visibleData.value = employees.value;
});

onMounted(() => {
  visibleData.value = employees.value;
});
</script>

<style></style>
