<template>
  <easiModal
    v-if="isOpen"
    @close="handleClose"
    :isHeader="false"
    :isBorder="false"
    lite
    center
    payroll
    rounded="3xl"
  >
    <div class="relative flex flex-col gap-4 rounded-3xl bg-white">
      <i
        @click="handleClose"
        class="pi pi-times text-sm text-dark-900 cursor-pointer absolute right-4 top-4"
      />

      <div class="w-full h-48">
        <img
          v-if="display"
          :src="display.cover"
          alt=""
          class="w-full h-full object-cover"
        />
      </div>

      <div class="py-6 p-4 md:px-6 flex flex-col gap-3">
        <h3 class="text-xl sm:text-2xl font-bold text-dark-900">
          {{ display && display.title ? display.title : "N/A" }}
        </h3>
        <p class="text-sm sm:text-lg">
          {{ display && display.description ? display.description : "N/A" }}
        </p>

        <div class="flex items-center gap-8 mt-2">
          <div
            @click="step = index"
            v-for="index in 3"
            :key="index"
            :class="[
              step === index
                ? 'bg-primary text-white'
                : 'bg-lighterGray text-dark-700',
            ]"
            class="cursor-pointer py-3 px-6 w-20 text-center font-bold flex-shrink-0 rounded-full"
          >
            {{ index }}
          </div>
        </div>
      </div>
    </div>
  </easiModal>
</template>

<script setup>
import image1 from "@/assets/img/salary-duration.png";
import image2 from "@/assets/img/salary-employee.png";
import image3 from "@/assets/img/salary-preview.png";

import { computed, ref } from "vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["close"]);

const step = ref(1);

const display = computed(() => {
  const list = {
    1: {
      title: "Add your Employee(s)",
      description: `Get started by clicking the button below to add employees. Select the employees you want to benefit from this feature.`,
      cover: image1,
    },
    2: {
      title: "Specify Advance Duration",
      description: `Once your employees are added, specify the duration for which the advance will be available to them.`,
      cover: image2,
    },
    3: {
      title: "Employees Withdraw Advance",
      description: `After setup, your employees can effortlessly withdraw the advance from their WorknProsper account.`,
      cover: image3,
    },
  };

  return list[step.value] || list[1];
});

const handleClose = () => {
  emit("close");
  step.value = 1;
};
</script>

<style></style>
